<template lang="html">
  <FrsModuleRoot
    :module="module"
    :module-path="['fieldRecordSystem', 'mapManagement']"
    :translation-namespaces="['mm', 'frs.maps.zoneMap']"
    :setup="loadAllMaps"
    persistent
  >
    <router-view />
  </FrsModuleRoot>
</template>

<script>
import {mapActions, mapMutations} from 'vuex'

import FrsModuleRoot from '@frs/components/base/FrsModuleRoot'

export default {
  components: {
    FrsModuleRoot
  },
  computed: {
    module () {
      return () => import('@store/modules/field-record-system/map-management')
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/mapManagement', [
      'invalidateLoadAllMaps',
      'loadAllMaps'
    ]),
    ...mapMutations('fieldRecordSystem/mapManagement', [
      'reset'
    ])
  },
  beforeRouteLeave (to, from, next) {
    if (to.params.year !== from.params.year) {
      this.invalidateLoadAllMaps()
      this.reset()
    }
    next()
  }
}
</script>

<style lang="scss" scoped>
</style>
